<template>
  <div class="hello">
   <!--  <h1>{{ title }}</h1> -->
    <p>
      The URL you used to reach this site appears to be incorrect
    </p>

  </div>
</template>

<script>
import * as config from "../config";

export default {
  name: "Login",
  data: () => ({
    title: `Welcome to ${config.applicationName}`,
  }),
  async created() {
  },
};
</script>
