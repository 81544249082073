<template>
  <div class="hello">
    <h1>Thank You!</h1>
    <h3>
      Thank you for participating in the survey. Your answers will help improve
      the organization.
    </h3>
    <p>You should close this browser window to complete this session</p>
  </div>
</template>

<script>
export default {
  name: "Complete",
  data: () => ({}),
  async created() {},
};
</script>
